/**
 * Ajax Form Submit Event Delegation
 *
 * All forms with an [data-request-validation] attribute will run through this.
 *
 * Options can be added as data-attributes, e.g. <form data-request-validation data-field-error-messages="true" data-form-message="false">
 *
 *

data-form-message - Prepend form with error/success message - default true
data-field-error-classes - Adds is-erroneous class to each form field which didn't pass validation - default true
data-field-error-messages - Adds specific error message after each form field - default false
 */

$(document).on('ajaxError', '[data-request-validation]', function(event, context, statusText, something, jqXHR) {
    var $form = $(this),
        prependMessage = ($form.attr('data-form-message') || true),
        appendMessage = ($form.attr('data-form-message-bottom') || false),
        addErrorClasses = ($form.attr('data-field-error-classes') || true),
        addErrorMessagesToFields = ($form.attr('data-field-error-messages') || true);

    // Get error message
    var errorMessage = (jqXHR.responseJSON && jqXHR.responseJSON.X_OCTOBER_ERROR_FIELDS)
        ? jqXHR.responseJSON.X_OCTOBER_ERROR_MESSAGE
        : 'There has been a problem submitting the form, please check you have completed it correctly and try again.';

    // Remove old ones
    $form.find('.form-error-message').remove();

    if (prependMessage === true)
    {
        // Prepend error message
        $form.prepend('<div class="form-error-message alert alert-warning">' + errorMessage + '</div>');
    }

    if (appendMessage === true)
    {
        // Append error message
        $form.append('<div class="form-error-message alert alert-warning">' + errorMessage + '</div>');
    }

    if (addErrorClasses === true || addErrorMessagesToFields === true)
    {
        var errorFields = (jqXHR.responseJSON && jqXHR.responseJSON.X_OCTOBER_ERROR_FIELDS);

        // Remove fields with error class
        $form.find(':input.is-erroneous').removeClass('is-erroneous');

        // Remove field error message
        $form.find('.input-error-message').remove();

        if (errorFields)
        {
            $.each(errorFields, function(field, message) {

                // fixes names and messages of fields in an array
                if(field.indexOf('.') !== -1) {
                    field = field.replace('.', '[') + ']';
                    message[0] = message[0].replace('.', ' ');
                }
                
                var $field = $form.find(':input[name="' + field + '"]');

                if (addErrorClasses === true) $field.addClass('is-erroneous');
                if (addErrorMessagesToFields === true) $field.after('<div class="input-error-message">' + message[0] + '</div>');
                
                if($field.length == 0)
                {
                    var $label = $form.find(':input[name="' + field + '[]"]').parents('.form-group').first('label');
                    if (addErrorMessagesToFields === true) $label.after('<div class="input-error-message">' + message[0] + '</div>');
                }

            });
        }
    }

    // This prevents the alert from showing.
    return false;
});

$(document).on('ajaxSuccess', '[data-request-validation]', function(event, context, statusText, jqXHR) {
    var $form = $(this),
        prependMessage = ($form.attr('data-form-message') || true),
        appendMessage = ($form.attr('data-form-message-bottom') || false),
        clearForm = ($form.attr('data-clear-on-success') || true)
        successMessage = ($form.attr('data-success-message') || "Thanks, your message has been submitted successfully");

    // Remove prepended error message
    $form.find('.form-error-message').remove();

    // Remove fields with error class
    $form.find(':input.is-erroneous').removeClass('is-erroneous');

    // Remove field error message
    $form.find('.input-error-message').remove();

    var message = '<div class="form-error-message alert alert-success">' + successMessage + '</div>';

    // Prepend success message
    if (prependMessage === true)
    {
        $form.prepend(message);
    }

    // Append success message
    if (appendMessage === true)
    {
        $form.append(message);
    }

    if(clearForm === true)
    {
        $form.find(':input')
            .removeAttr('checked').removeAttr('selected')
            .not('[type=checkbox], [type=radio], :button, :submit, :reset, :hidden').val('');
    }

});

$(document).on('ajaxSuccess', '[data-ga-event]', function(event, context, statusText, jqXHR) {
    var $form = $(this);
    
    // check if gogole analytics is available
    if (typeof ga === 'function')
    {
        // Track event in Google Analytics
        ga('send', {
            hitType: 'event',
            eventCategory: $form.data('ga-category'),
            eventAction: 'submit',
            eventLabel: $form.data('ga-label')
        });
    }

});
